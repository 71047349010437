.copyright {
    font-size: 13px;
}

.footer {
    width: 100%;
    padding: 15px 0%;
    margin-top: 15px;
    color: #074c58;
    text-align: center;
    background-image: linear-gradient(to bottom, white, #def9f8);
}
.total{
    width: 100%;
}

.info {
    text-align: center;
    color: white;
    font-size: 20px;
}

.footer-about {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.address {
    text-align: center;
}

.social-media-footer {
    height: 3%;
    width: 200px;
    margin: auto;
}

.social-media-icon {
    font-size: 23px;
    padding: 5px;
}
