.section1 {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1%;
}
.img-bg {
  background: linear-gradient(to right, white, mistyrose);
}
.bg {
  background: url("./../../assets/bg.jpg");
  background-repeat: repeat;
  position: absolute;
  opacity: 0.1;
  z-index: 0;
  width: 100%;
  height: 85%;
}
.sub-section-1-black {
  color: #074c58;
  background-image: linear-gradient(to right, white, #def9f8);
  width: 250px;
  height: 90px;
  text-align: center;
  border-left: 7px solid #277e8e;
  padding: 2px 4px 4px;
  margin: 15px 5px 0px 10px;
}
.sub-section-1-orange {
  color: #074c58;
  background-image: linear-gradient(to left, white, #def9f8);
  width: 250px;
  height: 90px;
  text-align: center;
  border-left: 7px solid #277e8e;
  padding: 2px 4px 4px;
  margin: 15px 5px 0px 10px;
}
.sub-section-1 {
  color: #074c58;
  background-image: linear-gradient(to left, white, #def9f8);
  border: 1px solid #fefefe;
  width: 250px;
  height: 90px;
  text-align: center;
  border-left: 7px solid #277e8e;
  padding: 4px 2px 0px 2px;
  margin: 15px 5px 0px 10px;
}
.sub-section-1-black:hover {
  box-shadow: 3px 5px 3px #c2fcfa;
  transition: 0.5s;
}
.sub-section-1-orange:hover {
  box-shadow: 3px 5px 3px #c2fcfa;
  transition: 0.5s;
}
.sub-section-1:hover {
  box-shadow: 3px 5px 3px #c2fcfa;
  transition: 0.5s;
}
.white-text {
  color: white;
}
.sub-section {
  color: #1b9e9c;
  width: 250px;
  height: 295px;
  text-align: center;
  padding: 10px;
  background: linear-gradient(to right, #2ba6b9, #1b9e9c);
  border: 1px solid transparent;
  border-radius: 10px;
  margin: 5px 10px;
}
.video {
  margin: 10px 0px;
  width: 100%;
  height: 300px;
  border: 1px solid transparent;
  box-shadow: 2px 5px 5px #1b9e9c;
}
.video-container {
  padding: 0px 5px;
}
.carousel-container {
  max-width: 400px;
}
.images-text-bl {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.underlined {
  color: #1b9e9c;
}
.gmap-container {
  padding: 5%;
}
