.hdr-container .logo a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 60px;
}

.hdr-container .logo a img {
  max-width: 100%;
  max-height: 60px;
}

.hdr-container .navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0 10px;
}

.hdr-container .navbar ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.hdr-container .navbar ul li a {
  text-decoration: none;
  color: white;
  font-size: 14px;
  display: block;
  height: 60px;
  line-height: 60px;
  cursor: pointer;
  padding: 0 10px;
}

.hdr-container .navbar ul li span:hover {
  color: #074d59;
  border-bottom: 2px solid #1b9e9c;
}

.hdr-container .navbar ul .close {
  display: none;
  text-align: right;
  padding: 5px;
  color: #f3f0db;
}

.hdr-container .navbar ul .close span {
  font-size: 40px;
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
}

.hdr-container .navbar .icon-bar {
  padding: 18px 8px;
  width: 50px;
  height: 60px;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
}

.hdr-container .navbar .icon-bar i {
  background-color: #ffffff;
  height: 1px;
}

@media only screen and (max-width: 565px) {
  .hdr-container {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .hdr-container .navbar {
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: 0;
  }

  .hdr-container .navbar ul {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    position: fixed;
    left: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background: #ffffff;
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-transition: left 0.3s;
    -o-transition: left 0.3s;
    transition: left 0.3s;
  }

  .hdr-container .navbar ul li a {
    padding: 10px;
    font-size: 16px;
    height: auto;
    line-height: normal;
    color: #555555;
  }

  .hdr-container .navbar ul .close {
    display: block;
  }

  .hdr-container .navbar .icon-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .hdr-container .navbar ._Menus-show {
    left: 0;
  }
}

.top-hdr-container .logo a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 60px;
}

.top-hdr-container .logo a img {
  max-width: 100%;
  max-height: 60px;
}

.top-hdr-container .navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0 10px;
}

.top-hdr-container .navbar ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.top-hdr-container .navbar ul li a {
  text-decoration: none;
  color: white;
  font-size: 14px;
  display: block;
  height: 60px;
  line-height: 60px;
  cursor: pointer;
  padding: 0 10px;
}

.top-hdr-container .navbar ul li span:hover {
  color: #074d59;
  border-bottom: 2px solid #1b9e9c;
}

.top-hdr-container .navbar ul .close {
  display: none;
  text-align: right;
  padding: 5px;
  color: #f3f0db;
}

.top-hdr-container .navbar ul .close span {
  font-size: 40px;
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
}

.top-hdr-container .navbar .icon-bar {
  padding: 18px 8px;
  width: 50px;
  height: 60px;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
}

.top-hdr-container .navbar .icon-bar i {
  background-color: #ffffff;
  height: 1px;
}

@media only screen and (max-width: 565px) {
  .top-hdr-container {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.top-hdr-container .navbar {
  -webkit-box-flex: 0;
  -ms-flex: 0;
  flex: 0;
}

.top-hdr-container .navbar ul {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  position: fixed;
  left: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #ffffff;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-transition: left 0.3s;
  -o-transition: left 0.3s;
  transition: left 0.3s;
}

.top-hdr-container .navbar ul li a {
  padding: 10px;
  font-size: 16px;
  height: auto;
  line-height: normal;
  color: #555555;
}

.top-hdr-container .navbar ul .close {
  display: block;
}

.top-hdr-container .navbar .icon-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.top-hdr-container .navbar ._Menus-show {
  left: 0;
}
.menu-span {
  color: #074d59;
  font-size: 14px;
  display: block;
  height: 60px;
  line-height: 60px;
  cursor: pointer;
  padding: 0 10px;
}
