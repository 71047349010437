.pr-cr-carousel {
  padding: 10px;
  color: #074c58;
  background-image: linear-gradient(to right, white, #def9f8);
  max-width: 900px;
  margin: auto;
}

.pr-cr {
  margin: 10px;
  padding: 10px;
  color: #074c58;
  background-image: linear-gradient(to right, white, #def9f8);
}

.thick-left-border {
  border-left: 5px solid white;
}

.box {
  height: 300px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.box>* {
  flex: 1 1 80px;
}