@import "~antd/dist/antd.css";

html,
body,
#root {
  height: 100%;
  width: 100%;
  padding: 0%;
  margin: 0%;
  font-family: "Montserrat", sans-serif;
  background-color: #ecfffe;
}

.container {
  height: fit-content;
}

.bold {
  font-weight: bold;
}

.bg-title {
  font-size: 3vw;
  font-weight: bold;
}

.bg-sub-title {
  font-size: 2vw;
}

.sub-sec-title {
  font-size: 18px;
}

@media only screen and (max-width: 565px) {
  .bg-title {
    font-size: 7vw;
  }
  .bg-sub-title {
    font-size: 3.5vw;
  }
  .sub-sec-title {
    font-size: 14px;
  }
}

.primary-colored {
  color: #1b9e9c;
  font-weight: bold;
}

.center {
  text-align: center;
}

.primary-title {
  color: #1b9e9c;
  font-weight: bold;
  padding-left: 3%;
  font-size: 28px;
  text-transform: uppercase;
}

.addon-title {
  color: white;
  font-weight: bold;
  padding-left: 3%;
  font-size: 20px;
  font-family: "Cinzel", serif;
  text-transform: uppercase;
}

.primary-wrapper {
  color: #1b9e9c;
  font-size: 30px;
  font-style: italic;
  padding-left: 1%;
  margin-top: -20px;
  font-family: "Tangerine", cursive;
}

.secondary-title {
  color: white;
  font-weight: bold;
  padding-left: 3%;
  padding-top: 3%;
  font-size: 28px;
  font-family: "Cinzel", serif;
  text-transform: uppercase;
}

.secondary-wrapper {
  color: white;
  font-size: 20px;
  padding: 0% 8%;
  font-family: "Abel", sans-serif;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.wrapper-of {
  color: #1b9e9c;
  font-size: 20px;
  padding: 0% 8%;
  font-family: "Abel", sans-serif;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.divider-paragraph-sec3 {
  color: #1b9e9c;
  font-size: 20px;
  padding: 0% 8%;
  font-family: "Abel", sans-serif;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
}

.divider-paragraph-sec3::first-letter {
  font-weight: bold;
}

.contentStyle {
  width: 100%;
  height: 100%;
}

.contentStyleS {
  height: 300px;
  width: 350px;
  border: none;
  max-width: 600px;
}

.title {
  font-family: "Cinzel", cursive;
  font-weight: bold;
  color: #1b9e9c;
}

.title-secondary {
  font-family: "Cinzel", cursive;
  font-weight: bold;
  color: white;
}

/* ANTDESIGN CUSTOMIZATON */
.ant-modal-body {
  padding: 0;
  margin: 0;
}

.ant-spin-dot-item {
  background-color: #1b9e9c;
}

.ant-modal-content {
  background-color: #1b9e9c;
  border: 1px solid transparent;
  box-shadow: 1px 1px 5px #cecdcd;
  border-radius: 25px;
  margin: auto;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  color: white;
  font-size: 20px;
  font-family: "abel", cursive;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
  border-left: 4px solid #074d59;
  color: #074d59;
}

.ant-btn {
  display: none;
}

.anticon svg {
  display: inline-block;
  color: #074d59;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
