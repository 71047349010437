.bg {
  background: url("./../../assets/bg.jpg");
  background-repeat: repeat;
  position: absolute;
  opacity: 0.05;
  z-index: 0;
  width: 100%;
  height: 85%;
}

.top-line {
  background-color: white;
  height: 60px;
}

.logo-name {
  color: white;
  font-weight: bold;
  font-size: 19px;
  padding-left: 10px;
  padding-top: 5px;
}

.social-media {
  height: 3%;
  padding-bottom: 1%;
}

.social-media-icon {
  color: white;
  font-size: 20px;
  padding: 5px;
}

.social-media-icon-top-wrapper {
  margin-right: 15px;
}

.header {
  border-bottom: 1px solid #1b9e9c;
  box-shadow: 1px 1px 5px #1b9e9c;
  background-color: #1b9e9c;
}

.menu {
  margin-top: 15px;
  font-size: 13px;
  color: rgb(73, 73, 73);
  text-align: "center";
  font-weight: bold;
  font-family: "Abel", sans-serif;
  text-align: center;
}

.logo {
  width: 55px;
  height: 55px;
  border: 1px solid transparent;
  border-radius: 28px;
}

.menu::first-letter {
  color: #1b9e9c;
}

.backToTop {
  color: #f0fefe;
  line-height: 40px;
  background-color: white;
  border: 1px solid #adeae9;
  border-radius: 4px;
  text-align: center;
}

.backToTopLayer {
  padding-right: 150px;
}

.backToTopArrow {
  font-size: 25px;
  padding-top: 10px;
}

.last {
  padding-left: 10%;
  text-align: center;
}

.box-left {
  animation-duration: 2s;
  animation-name: slideinLeft;
}

@keyframes slideinLeft {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 0%;
  }
}

.box-right {
  animation-duration: 2s;
  animation-name: slideinRight;
}

@keyframes slideinRight {
  from {
    margin-right: 100%;
  }

  to {
    margin-right: 0%;
  }
}

.img-text {
  position: absolute;
  border: 1px solid transparent;
  text-transform: uppercase;
  text-align: center;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.img-text-hdr-container {
  position: relative;
  text-align: left;
  color: white;
  background-color: aliceblue;
}

*::before,
*::after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.bg {
  background: url("./../../assets/bg.jpg");
  background-repeat: repeat;
  position: absolute;
  opacity: 0.08;
  z-index: 0;
  width: 100%;
  height: 85%;
}
.hdr-container {
  height: 70px;
  background-image: linear-gradient(to bottom, white, #ecfffe);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  border-bottom: 5px solid rgb(196, 231, 231);
}

.logo-name {
}

.logo {
  height: 55px;
  max-width: 90px;
  overflow: hidden;
  border-radius: 80px;
}

.menu-icon {
  font-size: 20px;
  color: white;
}

.close-icon {
  font-size: 20px;
  color: #1b9e9c;
}

.body-of-site {
  width: 100%;
}

.sub-hdr-container {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.drawer-title {
  color: white;
  font-weight: bold;
  text-align: center;
  align-content: center;
  font-size: 4vw;
}

.drawer-icon {
  color: #1b9e9c;
}

.img-title {
  font-size: 4vw;
  box-shadow: 2px 2px #1b9e9c, -0em 0 0.1em #979a9b;
  padding: 2px;
}
