.case-medical-img {
  object-fit: scale-down;
  max-width: 100%;
  border: 1px solid gainsboro;
  border-radius: 15px;
}

.before {
  opacity: 0.8;
}
