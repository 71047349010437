.outerdiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.innerdiv {
  margin: 1rem;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(4, 17rem);
}
.eachdiv {
  padding: 1rem 2rem;
  border-radius: 0.8rem;
  box-shadow: 5px 5px 20px #6d6b6b6b;
  color: white;
}
.div1 {
  background: #1B9E9C;
  grid-column: 1/3;
}
.div2 {
  background: white;
}
.div3 {
  background: #1B9E9C;
  color: white;
}
.userdetails {
  display: flex;
}
.imgbox {
  margin-right: 1rem;
}
.imgbox img {
  border-radius: 50%;
  width: 2rem;
  border: 2px solid #cec5c5;
}
.detbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.detbox p {
  margin: 0;
}
.detbox .name {
  color: white;
  font-size: 0.9rem;
  margin-bottom: 0.1rem;
  font-weight: 600;
}
.detbox .name.dark {
  color: black
}
.detbox .designation {
  color: white;
  opacity: 50%;
  font-size: 0.8rem;
}
.detbox .designation.dark {
  color: white;
}
.review h6 {
  font-size: 1.4rem;
  color: white;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0.8rem;
}
.review.dark h6 {
  color: black;
}
.review p {
  font-size: 0.95rem;
  color: white;
  font-weight: 500;
  line-height: 1.5;
}
.review.dark p {
  color: #0e0e0e;
}
.attribution {
  font-size: 1rem;
  line-height: 1.5;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  text-align: right;
}
.attribution a {
  text-decoration: none;
}

@media only screen and (max-width: 1000px) {
  .innerdiv {
    transform: scale(0.7);
  }
}
@media only screen and (max-width: 800px) {
  .innerdiv {
    transform: scale(0.6);
  }
}
@media only screen and (max-width: 600px) {
  .div1 {
    background-position-x: 10rem;
  }
  .innerdiv {
    display: flex;
    flex-direction: column;
    transform: scale(1);
    margin: 2rem;
    margin-bottom: 5rem;
  }
  .attribution {
    position: relative;
  }
}
